/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { setFilter, deleteFilter } from "redux-react/reducers/filtersReducers";
import lod_ from "lodash";
import ButtonMenu from "../ButtonMenu";
import DateFilter from "./DateFilter";
/**
 * Get filters from local storage for the front menus
 */
function getLocalStorageFrontValues(assistantID, page, attribute, filters) {
	let actualFilters = filters[assistantID];
	if (!actualFilters) return null;
	actualFilters = actualFilters[page];
	if (!actualFilters) return null;
	actualFilters = actualFilters[attribute];
	if (!actualFilters) return null;
	return actualFilters.front;
}
/**
 * Get filters from local storage for the back requests
 */
export function getLocalStorageBackValues(assistantID, page, filters) {
	let actualFilters = filters[assistantID];
	if (!actualFilters) return {};
	actualFilters = actualFilters[page];
	if (!actualFilters) return {};
	let keys = Object.keys(actualFilters);
	let backFilters = {};
	for (let key of keys) {
		backFilters[key] = actualFilters[key].back;
	}
	return backFilters;
}
/**
 * Parse filters defined by string into
 * an component's array
 * @param {[String]} filters
 */
export function parseFilters(
	assistantID,
	page,
	filters,
	storeFilters,
	dispatch,
	listValuesFilter = []
) {
	let newComponentFilters = [];

	if (!filters) {
		return newComponentFilters;
	}

	for (let filter of filters) {
		switch (filter.type) {
			case "boolean":
				newComponentFilters.push(
					<MDBox key={filter.attribute} mr={1}>
						<ButtonMenu
							fullHeight
							title={filter.label.fr}
							type="boolean"
							storage={getLocalStorageFrontValues(
								assistantID,
								page,
								filter.attribute,
								storeFilters
							)}
							inverted={filter.inverted}
							optionnal={filter.optionnal}
							handleUpdate={items => {
								if (lod_.isEmpty(items)) {
									let filterObject = {
										assistantID,
										page,
										attribute: filter.attribute
									};

									dispatch(deleteFilter(filterObject));
								} else {
									let value = lod_.isObject(items.value) ? items.value : { $in: [items.value] };

									let filterObject = {
										assistantID,
										page,
										attribute: filter.attribute,
										front: items,
										back: {
											name: filter.attribute,
											value,
											type: filter.type
										}
									};

									dispatch(setFilter(filterObject));
								}
							}}
						/>
					</MDBox>
				);
				break;
			case "datetime":
			case "date":
				newComponentFilters.push(
					<MDBox mr={1} key={filter.attribute} display="flex">
						<DateFilter
							assistantID={assistantID}
							page={page}
							storage={getLocalStorageFrontValues(
								assistantID,
								page,
								filter.attribute,
								storeFilters
							)}
							tooltip
							attribute={filter.attribute}
							title={filter.label.fr}
						/>
					</MDBox>
				);
				break;
			case "number":
			case "code":
				newComponentFilters.push(
					<MDBox key={filter.dictionaryPath} mr={1}>
						<ButtonMenu
							fullHeight
							title={filter.label.fr}
							items={filter.values}
							storage={getLocalStorageFrontValues(
								assistantID,
								page,
								filter.attribute,
								storeFilters
							)}
							handleUpdate={items => {
								if (!items.length) {
									let filterObject = {
										assistantID,
										page,
										attribute: filter.attribute
									};

									dispatch(deleteFilter(filterObject));
								} else {
									let values = items.map(i => i.value);

									let filterObject = {
										assistantID,
										page,
										attribute: filter.attribute,
										front: items,
										back: {
											name: filter.attribute,
											value: { $in: values },
											type: filter.type
										}
									};

									dispatch(setFilter(filterObject));
								}
							}}
						/>
					</MDBox>
				);
				break;
			default:
				newComponentFilters.push(
					<MDBox key={filter.attribute} mr={1}>
						<MDInput
							label={filter.label.fr}
							type={filter.type}
							value={getLocalStorageFrontValues(assistantID, page, filter.attribute, storeFilters)}
							storage={getLocalStorageFrontValues(
								assistantID,
								page,
								filter.attribute,
								storeFilters
							)}
							onChange={e => {
								if (!e.target.value.trim()) {
									dispatch(
										deleteFilter({
											assistantID,
											page,
											attribute: filter.attribute
										})
									);
								} else {
									let backObject = {
										name: filter.attribute,
										value: e.target.value,
										type: filter.type
									};
									dispatch(setFilter(backObject));
								}
							}}
						/>
					</MDBox>
				);
				break;
		}
	}

	return newComponentFilters;
}

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import "./style.css";
import lod_ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, Icon } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import i18n from "i18n";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import PromptStudioActions from "redux-react/actions/promptStudioActions";
import { display } from "redux-react/reducers/snackBarReducer";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import MDButton from "components/Basics/MDButton";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";

import PromptTemplatesList from "./components/RightPannel/PromptTemplatesList";
import ProcessInformations from "./components/MiddlePannel/ProcessInformations";
import MessageInformations from "./components/MiddlePannel/MessageInformations";
import MessageMenuItem from "./components/LeftPannel/MessageMenuItem";

import PromptPreview from "./components/MessagePreview";
import { COLLECTIONS } from "../../constants";
// A little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};
// Copy an item from one list to another list.
const copy = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const item = sourceClone[droppableSource.index];

	destClone.splice(droppableDestination.index, 0, { ...item, id: uuidv4() });
	return destClone;
};
// Moves an item from one list to another list.
const move = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

/**
 * Main component to add or edit process
 * @param {*} param0
 * @returns
 */
export default function PromptStudioAddProcess({ route }) {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	// idProps is the code of the process, if it exists, we are in edit mode
	const idProps = searchParams.get("id");

	const dispatch = useDispatch();
	// All the constants getted from the database
	const [llmModelProvider, setLlmModelProvider] = useState([]);
	const [promptTemplateCount, setPromptTemplateCount] = useState([]);
	const [promptTemplates, setPromptTemplates] = useState([]);
	const [llmModels, setLlmModels] = useState([]);
	// State is the list of messages used for the drag and drop
	const [state, setState] = useState({});
	// Step display is the current step displayed
	const [stepDisplay, setStepDisplay] = useState("infos");
	const [preview, setPreview] = useState(false);

	/* Decompose process in multiple parts */
	// 1- Process infos
	const [process, setProcess] = useState({
		name: "",
		description: "",
		code: ""
	});
	// 2- Step infos
	const [step, setStep] = useState({
		inputs: {},
		log: true,
		addHistory: false,
		outputs: {
			isJson: false,
			mappingKey: "",
			mappingOut: {}
		},
		modelCode: "",
		fallBackModelCode: ""
	});
	// 3- Messages
	const [messages, setMessages] = useState([]);

	/* Open the prompt preview */
	const openPreview = () => {
		setPreview(true);
	};

	/* Check if submit button is disabled */
	const isDisabled = () => {
		if (!process.name.trim() || !step.modelCode) {
			return true;
		}

		if (!messages.length) {
			return true;
		}

		let active = false;
		for (let m of messages) {
			if (m.promptParts.length > 0) {
				active = true;
			}
		}

		if (!active) {
			return true;
		}

		return false;
	};

	/* When process infos changes */
	const onChangeProcess = (path, value) => {
		setProcess(process => {
			let newProcess = lod_.cloneDeep(process);
			lod_.set(newProcess, path, value);
			return newProcess;
		});
	};

	/* When steps infos changes */
	const onChangeStep = (path, value) => {
		setStep(step => {
			let newStep = lod_.cloneDeep(step);
			lod_.set(newStep, path, value);
			return newStep;
		});
	};

	/* Add a new message */
	const addNewMessage = (id, parts = [], type = "human") => {
		setMessages(prev => {
			return [
				...prev,
				{
					type: id === "system_message" ? "system" : type,
					promptParts: parts,
					id
				}
			];
		});
	};

	/* Update a message */
	const updateMessage = (id, data) => {
		setMessages(prev => {
			return prev.map(message => {
				if (message.id === id) {
					return {
						...message,
						...data
					};
				}
				return message;
			});
		});

		/**
		 * When user change message type to system
		 * we need to update the state and the display
		 * and put the message on top of the list
		 */
		if (data.type === "system") {
			setMessages(prev => {
				let newMessages = lod_.cloneDeep(prev);
				let messageCopy = lod_.cloneDeep(newMessages.find(message => message.id === id));

				let messagesListWithoutMessage = newMessages.filter(message => message.id !== id);

				let newMessagesList = [
					{
						...messageCopy,
						id: "system_message"
					},
					...messagesListWithoutMessage
				];

				return newMessagesList;
			});

			setState(prev => {
				let newState = lod_.cloneDeep(prev);
				let message = lod_.cloneDeep(newState[id]);

				delete newState[id];

				let newID = "system_message";

				return {
					[newID]: message,
					...newState
				};
			});

			setStepDisplay("system_message");
		}
	};

	/* Delete a message */
	const deleteMessage = id => {
		setState(prev => {
			const newState = lod_.cloneDeep(prev);
			delete newState[id];

			let display = Object.keys(newState)[0] || "infos";
			setStepDisplay(display);

			return newState;
		});

		setMessages(prev => {
			return prev.filter(message => message.id !== id);
		});
	};

	/* Delete a prompt part inside a message */
	const deletePromptPart = (index, id) => {
		setState(prev => {
			return {
				...prev,
				[id]: prev[id].filter((item, i) => i !== index)
			};
		});
	};

	/* Get a message by id (state id) */
	const getMessageByID = id => {
		return messages.find(message => message.id === id);
	};

	/* Add a new message */
	const addList = idProps => {
		setState(prev => {
			const id = idProps || uuidv4();
			addNewMessage(id);
			return {
				...prev,
				[id]: []
			};
		});
	};

	/* Handle drag event */
	const onDragEnd = result => {
		const { source, destination } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		if (
			source.droppableId === "drag-list-messages" ||
			destination.droppableId === "drag-list-messages"
		) {
			if (source.droppableId !== destination.droppableId) {
				return;
			}

			return;
		}

		switch (source.droppableId) {
			case destination.droppableId:
				setState(prev => {
					return {
						...prev,
						[destination.droppableId]: reorder(
							state[source.droppableId],
							source.index,
							destination.index
						)
					};
				});
				break;
			case "ITEMS":
				setState(prev => {
					return {
						...prev,
						[destination.droppableId]: copy(
							promptTemplates,
							state[destination.droppableId],
							source,
							destination
						)
					};
				});
				break;
			default:
				setState(
					move(state[source.droppableId], state[destination.droppableId], source, destination)
				);
				break;
		}
	};

	/* Load prompt templates */
	const loadPromptTemplates = (cb = null) => {
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.promptTemplate,
				{
					sort: { name: 1 }
				},
				res => {
					setPromptTemplates(res?.items || []);
				}
			)
		);

		dispatch(
			PromptStudioActions.getTemplateCountFromProcess(res => {
				setPromptTemplateCount(res.result);
			})
		);

		if (cb) {
			cb();
		}
	};

	/* Get all LLM models / promptTemplates */
	const getConstVariables = () => {
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmModel,
				{
					query: { documentType: "llmModel" }
				},
				res => {
					if (res.items) {
						setLlmModels(res.items);
					}
				}
			)
		);

		loadPromptTemplates();

		/* Get model provider */
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmModelProvider,
				{
					query: { assistantID: { $exists: false } },
					catalog: "AI"
				},
				res => {
					setLlmModelProvider(res.items);
				}
			)
		);
	};

	/* Go back to process list */
	const goBackToProcessList = () => {
		navigate("/prompt-studio/process");
	};

	/* Delete a process (only when editing) */
	const deleteProcess = () => {
		if (!process._id) return;

		dispatch(
			FormAction.deleteItem(process._id, "llmProcess", { delete: true }, res => {
				dispatch(
					display({
						message: t("PROMPT.PROCESS.EDIT.successfullyDeleteProcess"),
						type: "success"
					})
				);
				goBackToProcessList();
			})
		);
	};

	/** Save change / create new process */
	const submit = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: t("PROMPT.PROCESS.EDIT.successfullyAddProcess"),
					type: "success"
				})
			);
			goBackToProcessList();
		};

		const onFailure = res => {
			dispatch(
				display({
					message: t("PROMPT.PROCESS.EDIT.thisProcessAlreadyExist"),
					type: "error"
				})
			);
		};

		let messagesCopy = lod_.cloneDeep(messages);
		let stepCopy = lod_.cloneDeep(step);

		let llmProcess = {
			...process,
			steps: [
				{
					...stepCopy,
					messages: messagesCopy.map(message => {
						delete message.id;
						return message;
					})
				}
			]
		};

		let cloneID = lod_.cloneDeep(llmProcess._id);
		delete llmProcess._id;

		let data = {
			values: llmProcess,
			target: "llmProcess",
			unique: { code: llmProcess.code },
			actions: []
		};

		if (idProps) {
			dispatch(FormAction.updateItem(cloneID, data, onSuccess, onFailure));
		} else {
			dispatch(FormAction.addItemEmpty(data, onSuccess, onFailure));
		}
	};

	/* Update the message in the state */
	useEffect(() => {
		let ids = Object.keys(state);

		for (let id of ids) {
			let promptPartsObject = state[id] || [];
			let promptPartsText = promptPartsObject.map(promptPart => promptPart.code);
			updateMessage(id, {
				promptParts: promptPartsText
			});
		}
	}, [state]);

	useEffect(() => {
		if (idProps && promptTemplates.length > 0 && !messages?.length) {
			dispatch(
				FormAction.getItemsFromCollection(
					COLLECTIONS.llmProcess,
					{
						query: { code: idProps }
					},
					res => {
						if (res.items.length) {
							let currentProcess = res.items[0];
							// Process
							setProcess({
								_id: currentProcess._id,
								name: currentProcess.name,
								description: currentProcess.description,
								code: currentProcess.code
							});
							// Step
							let currentStep = currentProcess.steps[0];
							setStep({
								inputs: currentStep.inputs || {},
								log: currentStep.log,
								addHistory: currentStep.addHistory,
								outputs: {
									isJson: currentStep.outputs.isJson,
									mappingKey: currentStep.outputs.mappingKey,
									mappingOut: currentStep.outputs.mappingOut || {}
								},
								modelCode: currentStep.modelCode,
								fallBackModelCode: currentStep.fallBackModelCode
							});

							let currentMessages = currentStep.messages || [];

							for (let message of currentMessages) {
								let randomID = uuidv4();
								let parts = message.promptParts;

								let prompts = [];

								for (let part of parts) {
									let prompt = promptTemplates.find(prompt => prompt.code === part);
									if (prompt) {
										prompts.push(prompt);
									}
								}

								let mappedPromptParts = prompts.map(prompt => prompt.prompt);

								setState(prev => {
									return {
										...prev,
										[randomID]: prompts
									};
								});

								addNewMessage(randomID, mappedPromptParts, message.type);
							}
						}
					}
				)
			);
		}
	}, [promptTemplates]);

	useEffect(() => {
		if (llmModels.length === 0 || promptTemplates.length === 0) {
			getConstVariables();
		}
		// Always add a first empty list
		if (Object.keys(state).length === 0 && !idProps) {
			addList("system_message");
			addList();
		}
	}, []);

	/* Choose the correct display depending on the step */
	const getCurrentDisplay = () => {
		switch (stepDisplay) {
			case "infos":
				return (
					<ProcessInformations
						onChangeProcess={onChangeProcess}
						process={process}
						step={step}
						onChangeStep={onChangeStep}
						llmModels={llmModels}
						llmModelProvider={llmModelProvider}
					/>
				);
			default:
				return (
					<MessageInformations
						list={stepDisplay}
						state={state}
						promptTemplates={promptTemplates}
						message={getMessageByID(stepDisplay)}
						messages={messages}
						updateMessage={updateMessage}
						deletePromptPart={deletePromptPart}
						deleteMessage={deleteMessage}
					/>
				);
		}
	};

	return (
		<DashboardLayout>
			{/* Prompt preview */}
			<Dialog
				open={preview}
				onClose={() => setPreview(false)}
				fullWidth
				maxWidth="xl"
				style={{ height: "100%" }}
				PaperProps={{
					style: {
						height: "100%"
					}
				}}
			>
				<DialogContent>
					<MDBox>
						{messages.map((message, i) => {
							return (
								<MDBox key={i}>
									<PromptPreview message={message} promptTemplates={promptTemplates} />
								</MDBox>
							);
						})}
					</MDBox>
				</DialogContent>
				<DialogActions>
					<MDButton onClick={() => setPreview(false)} color="info" variant="contained">
						{i18n.t("SETTINGS.close")}
					</MDButton>
				</DialogActions>
			</Dialog>
			{/* Main component */}
			<MDBox>
				<DashboardNavbar
					filters={[
						<MDBox display="flex" justifyContent="space-between">
							<ConfirmDialogButton
								onConfirm={() => {
									deleteProcess();
								}}
								component={
									<MDButton variant="contained" color="error">
										<Icon>delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
									</MDButton>
								}
								title={`${t("DIALOG.DELETE.delete")} "${process.name}"`}
								content={`${t("DIALOG.DELETE.confirmationDelete")} "${process.name}" ?`}
							/>
							<MDBox>
								<MDButton
									style={{ height: "100%", marginRight: "0.75rem" }}
									variant="outlined"
									color="info"
									onClick={goBackToProcessList}
								>
									{t("SETTINGS.cancel")}
								</MDButton>
								<MDButton
									style={{ height: "100%", marginRight: "0.75rem" }}
									variant="contained"
									color="info"
									onClick={openPreview}
								>
									<Icon>visibility</Icon>&nbsp;{t("SETTINGS.preview")}
								</MDButton>
								<MDButton
									style={{ height: "100%", marginRight: "0.75rem" }}
									variant="contained"
									color="info"
									onClick={submit}
									disabled={isDisabled()}
								>
									<Icon>save</Icon>&nbsp;Enregistrer
								</MDButton>
							</MDBox>
						</MDBox>
					]}
				/>
			</MDBox>
			<DragDropContext onDragEnd={onDragEnd}>
				<MDBox
					mt={2}
					display="flex"
					flexDirection="row"
					borderRadius="lg"
					bgColor="white"
					sx={{
						height: "85vh"
					}}
					style={{
						overflow: "hidden"
					}}
				>
					{/* Left pannel */}
					<MDBox
						flex="2"
						display="flex"
						flexDirection="column"
						sx={{
							boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
						}}
					>
						{/* Top navbar process */}
						<MDBox
							onClick={() => setStepDisplay("infos")}
							display="flex"
							className="clickablePannelItem containerStudioDetails"
						>
							<MDBox
								bgColor={stepDisplay === "infos" ? "info" : "white"}
								style={{
									width: "10px",
									height: "100%"
								}}
							></MDBox>
							<MDBox p={2} flex="1">
								<MDTypography variant="h5">
									{process.name || t("PROMPT.PROCESS.newProcess")}
								</MDTypography>
								<MDTypography variant="body2" fontSize="small">
									{process.description || t("PROMPT.PROCESS.EDIT.placeHolderDescription")}
								</MDTypography>
							</MDBox>
						</MDBox>
						{/* Messages list */}
						<MDBox flex="1" style={{ overflowY: "auto" }}>
							{
								// System message
								Object.keys(state).find(list => list === "system_message") && (
									<MessageMenuItem
										state={state}
										list="system_message"
										index={0}
										stepDisplay={stepDisplay}
										onClick={() => {
											setStepDisplay("system_message");
										}}
										className="clickablePannelItem"
										message={getMessageByID("system_message")}
									/>
								)
							}
							{step.addHistory && (
								<MDBox
									bgColor="light"
									borderRadius="lg"
									m={2}
									p={1}
									display="flex"
									justifyContent="center"
								>
									<MDTypography variant="h6" color="textSecondary">
										{t("PROMPT.PROCESS.EDIT.messageHistory")}
									</MDTypography>
								</MDBox>
							)}
							{Object.keys(state)
								.filter(list => list !== "system_message")
								.map((list, i) => {
									return (
										<MessageMenuItem
											state={state}
											list={list}
											index={i}
											stepDisplay={stepDisplay}
											onClick={() => {
												setStepDisplay(list);
											}}
											className="clickablePannelItem"
											message={getMessageByID(list)}
										/>
									);
								})}
						</MDBox>
						{/* Bottom navbar process */}
						<MDBox p={1} className="containerStudioDetails">
							<MDButton
								variant="contained"
								color="info"
								onClick={() => addList()}
								style={{ width: "100%" }}
							>
								{t("PROMPT.PROCESS.EDIT.addAMessage")}
							</MDButton>
						</MDBox>
					</MDBox>
					{/* Middle pannel */}
					<MDBox flex={stepDisplay === "infos" ? "6" : "3"} display="flex">
						{getCurrentDisplay()}
					</MDBox>
					{/* Right pannel */}
					{stepDisplay && stepDisplay !== "infos" && (
						<MDBox
							flex="3"
							display="flex"
							flexDirection="column"
							className="containerStudioDetails"
						>
							<PromptTemplatesList
								promptTemplates={promptTemplates}
								reloadTemplates={loadPromptTemplates}
								promptTemplateCount={promptTemplateCount}
							/>
						</MDBox>
					)}
				</MDBox>
			</DragDropContext>
		</DashboardLayout>
	);
}
